<template>
  <main>
    <h3>Welcome to Round Here</h3>
    <p>
      Thanks for signing up for the Round Here Investment Portal. You should
      have received an email with a link to validate your email address.
    </p>
    <p>
      You can
      <router-link :to="{ name: 'Login' }" class=" ms-1">sign in</router-link>
      now to get started.
    </p>
    <p>
      Round Here is a crowdfunding investment portal. You have registered with
      us as a potential investor. The investment opportunities hosted on our
      site include purchases of company shares, convertible notes, simple debt
      instruments, and SAFEs.
    </p>
    <p>
      For more information about each of these, visit the Knowledge Base area of
      our site.
      <a href="https://roundhere.co/rh/kb">https://roundhere.co/rh/kb</a>
    </p>
    <p>
      For our open investment offerings, please see
      <a href="https://roundhere.co/rh/offerings"
        >https://roundhere.co/rh/offerings</a
      >
      and check back often as new offerings will be added.
    </p>
    <p>
      The investment offerings on our site are conducted through Round Here,
      LLC; SEC file number 007-00284; Central Registration Depository (CRD)
      number 313211. Investment commitments will be held in escrow until the
      minimum amount of an offering is met, and after that, additional
      commitments will be held in escrow until the end of an offering.
    </p>
    <p>
      You may cancel an investment commitment until 48 hours before the deadline
      identified by the issuer. Round Here will notify you when the target
      offering amount has been met. At that time, if prior to the deadline, the
      issuer may close the offering early with notice to you about the new
      offering deadline at least five (5) business days prior to such new
      offering deadline (absent a material change that would require an
      extension of the offering and reconfirmation of the investment
      commitment). If you do not cancel an investment commitment before the
      48-hour period prior to the offering deadline, your funds will be released
      to the issuer upon closing of the offering and you will receive securities
      in exchange for your investment.
    </p>
    <p>
      If total investment commitments do not equal or exceed the target at the
      deadline, no securities will be sold in the offering, investment
      commitments will be cancelled and committed funds will be returned. If the
      issuer makes a material change to the terms of this offering, we will
      provide notice to you by email. If you do not reconfirm your investment
      commitment within five (5) business days of receiving that notice, your
      investment commitment will be cancelled and committed funds will be
      returned to you.
    </p>
    <p>
      The compensation paid to Round Here as an intermediary is (a) an initial
      fee paid by each issuer in the range of $1700 to $3000, an amount
      determined to cover costs of our escrow services provider over the course
      of the offering, and other costs of issuer onboarding, bad actor checks,
      and anti-money laundering (AML) checks, and (b) a success fee in the
      amount of 7% to 10% of the amount of each escrow closing and funding of
      the investment.
    </p>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>